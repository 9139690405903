<template>
  <section>
    <div style="position:fixed; top: 5px; right: 5px; z-index: 1000;" v-if="allBrandsOpen">
      <v-btn @click="allBrandsOpen = false" class="red--text"><font-awesome-icon :icon="['fas', 'x']"/></v-btn>
    </div>
    <v-dialog v-model="allBrandsOpen" max-width="400px">
      <v-card>
        <v-card-title class="mb-0 pb-0">
          <div class="goBold-4">all our brands</div>
        </v-card-title>
        <v-card-text>
          <ul class="mt-4">
            <li class="goBold-2 mt-1" v-for="(brand, i) in equipment.brands" :key="i">{{ brand }}</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container>
      <div class="mt-n6">
        <div class="d-flex align-center justify-center fill-height expanded" style="position: relative">
          <v-carousel hide-delimiters cycle :show-arrows="false" continuous :interval="5000" style="height: 800px !important;">
            <v-carousel-item v-for="(item, i) in equipment.images" :key="i" style="height: 800px !important;">
              <retail-images :image-name="item" height="800px" style="height: 800px !important;"></retail-images>
            </v-carousel-item>
          </v-carousel>
          <v-overlay :absolute="true" :opacity="0.5" z-index="4" class="insetShadow">
            <div class="pa-10 text-center">
              <div class="goBold-4 pb-4">
                premier equipment
              </div>
              <div class="goBold-2 pb-4">
                you can trust
              </div>
            </div>
            <div style="height: 220px !important;">
              <vue-marquee-slider id="marquee-slider" :speed="120000" :width="175" :space="150" style="z-index: 1; overflow: hidden; width: 100vw !important;">
                <div class="white d-flex rounded-lg px-3 py-5 elevation-1" style="height: 100px; width: 100px;" v-for="(brand, i) in equipment.brands" :key="i">
                  <div :style="`background: url('https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/companies/brands/${brand}.jpeg') no-repeat center; height: 100%; width: 100%; background-size: contain;`"></div>
                </div>
              </vue-marquee-slider>
            </div>
            <div class="d-flex justify-center mt-8" style="width: 100%;">
              <outlined-white-button block @click="allBrandsOpen = true">all our brands</outlined-white-button>
            </div>
          </v-overlay>
        </div>
        <v-row class="my-6 mt-12">
          <v-col cols="12" md="6">
            <div class="d-flex justify-center">
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="bcd"></diving-icon>
              </div>
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="camera"></diving-icon>
              </div>
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="fins"></diving-icon>
              </div>
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="knife"></diving-icon>
              </div>
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="light"></diving-icon>
              </div>
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="wetsuit"></diving-icon>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="d-flex justify-center">
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="dsmb"></diving-icon>
              </div>
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="mask"></diving-icon>
              </div>
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="reg"></diving-icon>
              </div>
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="spg"></diving-icon>
              </div>
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="scooter"></diving-icon>
              </div>
              <div style="height: 100%; width: 100%;">
                <diving-icon icon="tanks"></diving-icon>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>
              <v-carousel hide-delimiters cycle :show-arrows="false" continuous :interval="8000" class="mt-5" style="height: 125px;">
                <v-carousel-item v-for="(item, i) in equipment.ratings" :key="i">
                  <div class="text-center">
                    <span class="gothic-1">{{ item.comment }}</span>
                    <v-rating
                      color="#FFD700"
                      full-icon="fa-solid fa-star"
                      hover
                      length="5"
                      readonly
                      size="15"
                      :value="item.rating"
                      class="mb-2"
                    ></v-rating>
                    <span class="goBold-1">{{ item.author }}</span>
                  </div>
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="mt-4">
        <v-parallax src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/retail/store-tools.jpg" alt="Store Tools" class="parallax-title rounded-lg">
          <v-overlay :absolute="true" :opacity="0.8">
            <div class="pa-10 text-center">
              <div class="goBold-5 pb-4">
                let's talk servicing
              </div>
            </div>
          </v-overlay>
        </v-parallax>
      </div>
      <v-row class="mt-3">
        <v-col cols="12" md="4" order-sm="1" order="3">
          <retail-images image-name="guages" height="500" img-class="rounded-lg">
            <v-overlay :absolute="true" :opacity="0.5" z-index="4" class="insetShadow text-center">
              <div class="titleLogoSmall">
                SALT & SEA
              </div>
              <div class="subtitleLogoSmall mt-n4">
                SCUBA
              </div>
              <div class="gothic-2 mt-6">
                A Commitment to Excellence
              </div>
            </v-overlay>
          </retail-images>
        </v-col>
        <v-col cols="12" md="8" order-sm="2" order="1">
          <v-row>
            <v-col cols="6" sm="4" order-sm="1" order="2">
              <bento-box-card>
                <div class="goBold-2 black--text drop-font-sm">Quick</div>
                <font-awesome-icon :icon="['fas', 'clock']" size="2xl" class="secondary--text my-2 mt-4"/>
                <div class="goBold-2 black--text drop-font-sm">Turnaround</div>
              </bento-box-card>
            </v-col>
            <v-col cols="12" sm="4" order-sm="2" order="1">
              <bento-box-card>
                <div class="goBold-2 black--text drop-font-sm">Onsite Dive</div>
                <font-awesome-icon :icon="['fas', 'water']" size="2xl" class="primary--text my-2 mt-4"/>
                <div class="goBold-2 black--text drop-font-sm">Testing</div>
              </bento-box-card>
            </v-col>
            <v-col cols="6" sm="4" order-sm="3" order="5">
              <bento-box-card>
                <div class="goBold-2 black--text drop-font-sm">Tech</div>
                <font-awesome-icon :icon="['fas', 'microscope']" size="2xl" class="blue-grey--text my-2 mt-4"/>
                <div class="goBold-2 black--text drop-font-sm">Precision</div>
              </bento-box-card>
            </v-col>
            <v-col cols="6" sm="4" order-sm="4" order="4">
              <bento-box-card>
                <div class="goBold-2 black--text drop-font-sm">Tailored</div>
                <font-awesome-icon :icon="['fas', 'magnifying-glass']" size="2xl" class="primary--text my-2 mt-4"/>
                <div class="goBold-2 black--text drop-font-sm">Solutions</div>
              </bento-box-card>
            </v-col>
            <v-col cols="12" sm="4" order-sm="5" order="6">
              <bento-box-card>
                <div class="goBold-2 black--text drop-font-sm">Unmatched</div>
                <font-awesome-icon :icon="['fas', 'ruler']" size="2xl" class="orange--text my-2 mt-4"/>
                <div class="goBold-2 black--text drop-font-sm">Craftsmanship</div>
              </bento-box-card>
            </v-col>
            <v-col cols="6" sm="4" order-sm="6" order="3">
              <bento-box-card>
                <div class="goBold-2 black--text drop-font-sm">Expert</div>
                <font-awesome-icon :icon="['fas', 'brain']" size="2xl" class="pink--text my-2 mt-4"/>
                <div class="goBold-2 black--text drop-font-sm">Gear Handling</div>
              </bento-box-card>
            </v-col>
            <v-col cols="6" sm="4" order-sm="7" order="7">
              <bento-box-card>
                <div class="goBold-2 black--text drop-font-sm">Industry-Leading</div>
                <font-awesome-icon :icon="['fas', 'user-graduate']" size="2xl" class="accent--text my-2 mt-4"/>
                <div class="goBold-2 black--text drop-font-sm">Training</div>
              </bento-box-card>
            </v-col>
            <v-col cols="12" sm="4" order-sm="8" order="9">
              <bento-box-card>
                <div class="goBold-2 black--text drop-font-sm">Feedback</div>
                <font-awesome-icon :icon="['fas', 'comments']" size="2xl" class="light-green--text my-2 mt-4"/>
                <div class="goBold-2 black--text drop-font-sm">Driven</div>
              </bento-box-card>
            </v-col>
            <v-col cols="6" sm="4" order-sm="9" order="8">
              <bento-box-card>
                <div class="goBold-2 black--text drop-font-sm">Quality</div>
                <font-awesome-icon :icon="['fas', 'life-ring']" size="2xl" class="orange--text my-2 mt-4"/>
                <div class="goBold-2 black--text drop-font-sm">Assurance</div>
              </bento-box-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <wave-effect></wave-effect>
    <v-sheet color="primary" class="d-flex align-center justify-center" style="height: 500px;">
      <div class="white--text text-center mt-12 mb-12" style="width: 400px;">
        <div class="goBold-3">come get</div>
        <div class="goBold-3">equipment and servicing</div>
        <v-divider class="white mt-4"></v-divider>
        <div class="titleLogoSmall">
          SALT & SEA
        </div>
        <div class="subtitleLogoSmall mt-n4">
          SCUBA
        </div>
        <div class="goBold-2 mt-4">(602) 844-5732</div>
        <div class="goBold-1 mt-6">4139 W Bell Rd. Ste 17</div>
        <div class="goBold-1 mt-2">Phoenix, AZ 85053</div>
        <v-divider class="white mt-6"></v-divider>
        <div class="mt-5 gothic-2">"Empowering Arizona adventurers to embrace the depths with a passion."</div>
      </div>
    </v-sheet>
  </section>
</template>

<script>
import RetailImages from '@/components/retailImages.vue'
import WaveEffect from '@/components/waveEffect.vue'
import DivingIcon from '@/components/divingIcon.vue'
import OutlinedWhiteButton from '@/components/outlinedWhiteButton.vue'
import BentoBoxCard from '@/components/bentoBoxCard.vue'

export default {
  name: 'Equipment',
  components: {
    BentoBoxCard,
    OutlinedWhiteButton,
    DivingIcon,
    WaveEffect,
    RetailImages
  },
  data: () => ({
    allBrandsOpen: false,
    equipment: {
      ratings: [
        {
          rating: 5,
          comment: 'I cannot recommend Salt & Sea Scuba enough! Check them out! They have the best stuff!',
          author: 'David D.'
        },
        {
          rating: 5,
          comment: 'Everyone here was very welcoming, kind and knowledgeable.',
          author: 'Savannah S.'
        },
        {
          rating: 5,
          comment: 'Awesome dive shop stocked full with just about anything you would need to go diving! ',
          author: 'Matt'
        },
        {
          rating: 5,
          comment: 'Great scuba center! I\'ve taken my OWD course there and enjoyed every minute of it.',
          author: 'Manuel O.'
        },
        {
          rating: 5,
          comment: 'Took the open water course with Michael and Joe. Absolute blast, great experience, easy learning, and lasting knowledge.',
          author: 'Grady T.'
        }
      ],
      brands: ['amx', 'apeks', 'aqualung', 'atomic', 'bare', 'diveright', 'dive-systems', 'garmin', 'helium', 'hollis', 'oceanic', 'osprey', 'padi', 'poseidon', 'ratio-computers', 'seaandsea', 'sharkskin', 'surffur', 'suunto', 'tusa', 'waterproof', 'xdeep', 'xsscuba', 'zeagle'],
      images: ['mask-wall', 'fins-wall', 'dsmb-wall-horizontal', 'bcd-manikin']
    }
  }),
  created () {
    this.doSetDocumentTitle('Equipment and Services', true)
  }
}
</script>

<style scoped>
.parallax-title {
  height: 400px !important;
}

.titleLogoSmall {
  font-family: 'Gothic', sans-serif !important;
  font-weight: 800;
  font-size: 46px !important;
  letter-spacing: -2px;
}

.subtitleLogoSmall {
  font-family: 'Gothic', sans-serif !important;
  font-size: 22px !important;
  font-weight: 300;
}

::v-deep .v-carousel__item {
  height: 800px !important;
}
</style>
